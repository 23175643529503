import React, { useState } from "react";
import villasImg from "../images/villasimg.jpg";
import villaImgSM from '../images/villasimgSM1.jpg';
import Header from "../shared/Header";
import gallink from "../images/gallink.jpg";
import villaimgsec from "../images/villaimgsec.jpg";
import Layout from "../shared/Layout";
import {
  Col,
  Content,
  Section,
  H2,
  H3,
  P,
  Btn,
  Line,
  Arrow,
  Grid2,
} from "../styles/shared";
import styled from "styled-components";
import Footer from "../shared/Footer";
import Menu from "../shared/Menu";
import redirect from '../shared/Redirect';

const SpecialTxt = styled.p`
  font-family: "beauty";
  font-size: 63px;
  letter-spacing: 0.05em;
  line-height: 0.8em;
  text-align: center;
  color: rgb(40, 89, 132);
  font-weight: 400;
`;

const BG = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:before {
    content: "";
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    position: absolute;
    background-image: url(${gallink});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.58;
  }
`;

const Li = styled.li`
  color: rgb(123, 116, 110);
  font-size: 18px;
  font-family: "brandon", sans-serif;
  margin-bottom: -0.8rem;
  &:before {
    content: "• ";
    color: rgb(40, 89, 132);
    font-size: 28px;
    margin-right: 0.7rem;
  }
`;

const Li2 = styled.li`
  color: rgb(123, 116, 110);
  font-size: 17px;
  font-family: "brandon", sans-serif;
  margin-bottom: -0.8rem;
  &:before {
    content: "• ";
    font-size: 28px;
    color: rgb(245, 169, 19);
    margin-right: 0.7rem;
  }
`;

const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  align-items: center;
  margin-top: 40px;
  gap: 40px;
`

const PriceCard = styled.div`
  width: 350px;
  height: 130px;
  text-align: right;
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
`;

const prices = [
  {
    from: "MAY 15",
    to: "MAY 31",
    price: "340",
  },
  {
    from: "JUN 1",
    to: "JUN 9",
    price: "369",
  },
  {
    from: "JUN 10",
    to: "Jun 19",
    price: "446",
  },
  {
    from: "Jun 20",
    to: "Jun 30",
    price: "534",
  },
  {
    from: "Jul 1",
    to: "Aug 31",
    price: "892",
  },
  {
    from: "Sep 1",
    to: "Sep 9",
    price: "534",
  },
  {
    from: "Sep 10",
    to: "Sep 19",
    price: "446",
  },
  {
    from: "Sep 20",
    to: "Sep 30",
    price: "369",
  },
  {
    from: "Oct 1",
    to: "Oct 31",
    price: "340",
  },
];

export default function Villas() {
  const [open, setOpen] = useState(false);
  return (
    <Layout>
      {!open ? (
        <>
          <Header bg={villasImg} type="island" setOpen={setOpen} sbg={villaImgSM} />
          <Section>
            <Content>
              <Col align="center">
                <H2 align="center">
                  <span>NATURE AT ITS FINEST</span>
                  <Line align="center" />
                </H2>
                <H3 align="center">ESCAPE UNDER THE STARRY SKY</H3>
                <P color="rgb(123,116,110)" align="center">
                  La Villa Bleue's secluded beachside location promotes a sense
                  of true peacefulness and escapism that will capture your heart
                  and soul from the moment you arrive. It is the ideal setting
                  to drop all worries and distractions, soak up as much of
                  Hvar's infamous sunshine as you can, and create enduring
                  memories with your loved ones.
                </P>
                <Btn
                  border="1px solid #f5a913"
                  color="#7b746e"
                  width="358px"
                  hbgcolor="#f5a913"
                  hcolor="#fff"
                  style={{ marginTop: "30px" }}
                  onClick={() => redirect("/booking")}
                >
                  <span>BOOK YOUR ESCAPE</span>
                  <Arrow />
                </Btn>
              </Col>
            </Content>
          </Section>
          <Section bg="rgb(238,236,234)">
            <Content>
              <SpecialTxt>
                We had a phenomenal stay at La Villa Bleue.A true paradise on
                Hvar. The house is literally a few steps away from the most
                beautiful beach with the clearest waters I’ve ever swam in!{" "}
                <br /> <br />​ Inside the house was immaculately clean. You
                could tell that every small touch had been considered ​ <br />{" "}
                <br />
                Our family now has such fond memories of those gorgeous summer
                evenings on the terrace overlooking the sea, and we can’t wait
                to come back next year."
              </SpecialTxt>
              <H2 align="center" style={{ marginTop: "2rem" }}>
                BETH
              </H2>
              <H2 align="center" color="rgb(164,155,147)">
                GUEST 2021
              </H2>
              <Line align="center" />
            </Content>
          </Section>
          <Section>
            <BG>
              <Btn
                border="1px solid #fff"
                width="335px"
                bgcolor="#7b746e"
                hbgcolor="rgba(245, 169, 19, 0.7)"
                hcolor="#fff"
                style={{ zIndex: 99 }}
                onClick={() => redirect("/gallery")}
              >
                <span>FULL GALLERY</span>
                <Arrow />
              </Btn>
            </BG>
          </Section>
          <Section>
            <Content>
              <Col align="start">
                <H2 align="left">
                  <span>AT YOUR SERVICE</span>
                  <Line align="start" />
                </H2>
                <H3 align="left">DEDICATED CONCIERGE</H3>
                <P color="rgb(123,116,110)" align="left">
                  While staying at La Villa Bleue, we will be on hand 24/7 to
                  answer any queries, as well as helping you to arrange any day
                  trips, excursions, and transport as required. With our
                  in-depth knowledge of the property, destination, and entire
                  island at your disposal, we help to take the worries out of
                  travelling as a family – whether it be your first or fiftieth
                  trip to Hvar.
                </P>
                <P color="rgb(123,116,110)" align="left">
                  Upon booking, you will be provided with a detailed pdf
                  guidebook for the villa, which includes details of all our
                  hand-selected, tried-and-tested restaurants and activities on
                  the island.
                </P>
                <P color="rgb(123,116,110)" align="left">
                  Optional, charged additional services at La Villa Bleue,
                  offered upon request, include:
                </P>
                <ul style={{ listStyle: "none" }}>
                  <Li>Grocery shopping</Li>
                  <Li>Local food and beverage delivery</Li>
                  <Li>Chef service</Li>
                  <Li>Taxi and transfers service</Li>
                  <Li>Private personal training</Li>
                  <Li>Private kickboxing classes</Li>
                  <Li>Private yoga classes</Li>
                  <Li>Car, boat, scooter and bicycle rental</Li>
                  <Li>Bar School cocktail masterclass</Li>
                </ul>
              </Col>
            </Content>
          </Section>
          <Section>
            <img src={villaimgsec} style={{ width: "100%", height: "auto" }} />
          </Section>
          <Section>
            <Content>
              <H3 align="right">NIGHTLY RATES</H3>
              {/* <Grid2 style={{ gap: "40px", marginTop: "40px" }}>
                <Card>
                  <H2 align="right">MAY 1 - MAY 31</H2>
                  <P align="right" color="rgb(123,116,110)">
                    €340
                  </P>
                  <Line align="stretch" />
                </Card>
                <Card>
                  <H2 align="right">SEP 1 – SEP 9</H2>
                  <P align="right" color="rgb(123,116,110)">
                    €534
                  </P>
                  <Line align="stretch" />
                </Card>
                <Card>
                  <H2 align="right">JUN 1 – JUN 9</H2>
                  <P align="right" color="rgb(123,116,110)">
                    €369
                  </P>
                  <Line align="stretch" />
                </Card>
                <Card>
                  <H2 align="right">SEP 10 – SEP 19</H2>
                  <P align="right" color="rgb(123,116,110)">
                    €446
                  </P>
                  <Line align="stretch" />
                </Card>
                <Card>
                  <H2 align="right">JUN 10 – JUN 19</H2>
                  <P align="right" color="rgb(123,116,110)">
                    €446
                  </P>
                  <Line align="stretch" />
                </Card>
                <Card>
                  <H2 align="right">SEP 20 – SEP 30</H2>
                  <P align="right" color="rgb(123,116,110)">
                    €369
                  </P>
                  <Line align="stretch" />
                </Card>
                <Card>
                  <H2 align="right">JUN 20 – JUN 30</H2>
                  <P align="right" color="rgb(123,116,110)">
                    €534
                  </P>
                  <Line align="stretch" />
                </Card>
                <Card>
                  <H2 align="right">OCT 1 – OCT 15</H2>
                  <P align="right" color="rgb(123,116,110)">
                    €340
                  </P>
                  <Line align="stretch" />
                </Card>
                <Card>
                  <H2 align="right">JUL 1 – AUG 31</H2>
                  <P align="right" color="rgb(123,116,110)">
                    €892
                  </P>
                  <Line align="stretch" />
                </Card>
                <P
                  align="right"
                  color="rgb(123,116,110)"
                  style={{ marginTop: "auto" }}
                >
                  All prices are inclusive of tourist tax.
                </P>
              </Grid2> */}
              <PriceWrapper>
                {prices.map((rate, index) => (
                  <PriceCard key={index}>
                    <H2 align="right" style={{ textTransform: "uppercase" }}>
                      {rate.from} - {rate.to}
                    </H2>
                    <P align="right" color="rgb(123,116,110)">
                      €{rate.price}
                    </P>
                    <Line align="stretch" />
                  </PriceCard>
                ))}
                <P
                  align="right"
                  color="rgb(123,116,110)"
                  style={{ marginTop: "auto", width: "350px" }}
                >
                  All prices are inclusive of tourist tax.
                </P>
              </PriceWrapper>
            </Content>
          </Section>
          <Section bg="rgb(238,236,234)">
            <Content>
              <Col align="start">
                <H3 align="left">ADDITIONAL INFORMATION</H3>
                <ul style={{ listStyle: "none", fontSize: "17px" }}>
                  <Li2>Check in from 16:00</Li2>
                  <Li2>Check out before 10:00</Li2>
                  <Li2>Minimum stay of 6 nights in summer</Li2>
                  <Li2>Cleaning fee of €200 per stay</Li2>
                  <Li2>Car is a requirement for access</Li2>
                  <Li2>50% payment required to secure booking</Li2>
                  <Li2>50% payment required 60 days before arrival</Li2>
                  <Li2>100% refund if cancelled 60 days before arrival</Li2>
                  <Li2>50% refund if cancelled 30 days before arrival</Li2>
                  <Li2>€750 damages deposit required</Li2>
                  <Li2>Pets and parties are not allowed</Li2>
                  <Li2>Smoking is allowed only in outdoor areas</Li2>
                </ul>
                <Btn
                  border="1px solid #3C85C6"
                  bgcolor="transparent"
                  width="335px"
                  color="#7B746E"
                  hborder="1px solid rgba(245, 169, 19, 0.8)"
                  hbgcolor="rgba(245, 169, 19, 0.8)"
                  hcolor="#fff"
                  style={{ marginTop: "40px" }}
                  onClick={() => redirect("/amenities")}
                >
                  <span>ALL AMENITIES</span>
                  <Arrow />
                </Btn>
              </Col>
            </Content>
          </Section>
          <Footer type="full" />
        </>
      ) : (
        <Menu setOpen={setOpen} />
      )}
    </Layout>
  );
}
